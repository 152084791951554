<template>
  <div>
    <h5 class="page-header-text mb-4">{{ t('verify_your_phone_number') }}</h5>
    <div v-if="state == 1" id="phone-sign-in-recaptcha">
      <p>{{ t('bluuu_can_reach_you') }}</p>
      <b-form-group :label="$t('form_label.country')">
        <v-select
          :labelTitle="$t('form_label.nothing_selected')"
          class="custom-v-select"
          :options="countryOptions"
          v-model="dialCodeSelected"
        />
      </b-form-group>
      <b-form-group :label="$t('form_label.phone_number')">
        <b-input
          v-model="phoneNumber"
          placeholder="0XXXXXXXXX"
          :state="phoneNumberState"
          aria-describedby="input-live-feedback"
        ></b-input>
        <div v-if="!isCorrectPhoneNumber() && phoneNumber && phoneNumber.length > 9 || phoneNumber &&  !isNumber(phoneNumber)" class="text-danger mt-2">{{ $t('form_label.phone_error') }}</div>
        <b-form-invalid-feedback id="input-live-feedback">
          {{ $t(`pages.verify_phone.phone_number_used_types.${bound_with}`) }}
        </b-form-invalid-feedback>
      </b-form-group>
      <!-- <AgreementCheckBox v-model="isAgreePolicy" /> -->
    </div>
    <div v-else>
      <p>
        {{ $t('pages.verify_phone.check_your_phone') }} ({{ phoneNumberWithPrefixCountryCode }})
        {{ $t('pages.verify_phone.for_a_text_from_us') }} (Ref : {{ confirmationResult.refno }})
      </p>
      <b-form-group :label="$t('pages.verify_phone.enter_digits')">
        <b-input v-model="confirmCode"></b-input>
      </b-form-group>
    </div>
    <div id="recapcha-container" v-if="state === 1"></div>
    <b-button
      variant="primary"
      size="lg"
      class="mt-4"
      block
      @click="doVerify"
      :disabled="!canVerify"
      >{{ $t(`button.${buttonText}`) }}</b-button
    >
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator'
import firebase from 'firebase/app'
import { firebaseAuth } from '../config/firebase'
import VSelect from '@alfsnd/vue-bootstrap-select'
import ApiClient from '../services'
import AgreementCheckBox from './AgreementCheckBox'

@Component({
  components: { VSelect, AgreementCheckBox },
})
export default class VerifyPhoneComponent extends Vue {
  isAgreePolicy = false
  isProcessing = false
  phoneNumberIsExist = null
  state = 1
  recaptchaVerifier = undefined
  isVerifiedRecaptcha = false
  confirmationResult = undefined
  phoneNumber = ''
  dialCodeSelected = { name: 'Thailand', dial_code: '+66', code: 'TH', text: 'Thailand (+66)' }
  confirmCode = null
  paymentPayload = undefined
  countryOptions = []
  bound_with = null

  mounted() {
    this.initCountryOption()
    this.$nextTick(() => {
      this.initRecaptchaVerifier()
      this.paymentPayload = this.paymentBody
    })
  }

  initRecaptchaVerifier() {
    this.recaptchaVerifier = this.createRecaptchaVerifier()
    this.recaptchaVerifier.render().then((widgetId) => (window.recaptchaWidgetId = widgetId))
  }
  isNumber(str){
        var pattern = /^\d+$/;
        return pattern.test(str);  // returns a boolean
    }

  createRecaptchaVerifier() {
    return new firebase.auth.RecaptchaVerifier('recapcha-container', {
      size: 'invisible',
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        submitPhoneNumberAuth()
        this.isVerifiedRecaptcha = true
      },
      'expired-callback': () => {
        // Response expired. Ask user to solve reCAPTCHA again.
        this.isVerifiedRecaptcha = false
      },
    })
  }

  changePhoneNumber() {
    this.state = 1
    this.phoneNumber = ''
    setTimeout(() => {
      this.recaptchaVerifier.clear()
      this.initRecaptchaVerifier()
    }, 1500)
  }

  initCountryOption() {
    const options = [{ name: 'Thailand', dial_code: '+66', code: 'TH' }].map((obj) => ({
      ...obj,
      text: `${obj.name} (${obj.dial_code})`,
    }))
    this.countryOptions = options
  }

  isCorrectPhoneNumber() {
    return ((this.phoneNumber.length === 10) && (this.phoneNumber.charAt(0) === '0')) || ((this.phoneNumber.length === 9) && !(this.phoneNumber.charAt(0) === '0'))
  }

  get buttonText() {
    return 'verify'
  }

  get phoneNumberWithPrefixCountryCode() {
    return `${this.dialCodeSelected.dial_code}${this.phoneNumber.slice(1)}`
  }
  get canVerify() {
    return this.isCorrectPhoneNumber() && !this.isProcessing && this.isNumber(this.phoneNumber)
  }

  get phoneNumberState() {
    return this.phoneNumberIsExist === null ? null : !this.phoneNumberIsExist
  }

  async doVerify() {
    if (this.state === 1) {
       let phone = this.phoneNumber
      if((phone.length === 10) && (phone.charAt(0) === '0'))
      {this.phoneNumberIsExist = await this.checkPhoneExsiting()
      this.sendCode()}
      if((phone.length === 9) && !(phone.charAt(0) === '0')){
        this.phoneNumber = "0"+ phone
      this.phoneNumberIsExist = await this.checkPhoneExsiting()
      this.sendCode()
      }
    } else {
      this.doConfirmCode()
    }
  }

  async checkPhoneExsiting() {
    if (this.isCorrectPhoneNumber()) {
      this.phoneNumberIsExist = null

      try {
        const result = await ApiClient.checkPhoneExisting(this.phoneNumber)
        const { existing, bound_with } = result.data
        this.bound_with = bound_with
        return existing
      } catch (e) {
        console.error(e.response)
      }
    }
  }

  async sendCode() {
    if (this.isCorrectPhoneNumber() && this.phoneNumberState) {
      this.isProcessing = true
      try {
        const response = await ApiClient.sentOTPToPhone(
          this.phoneNumberWithPrefixCountryCode.slice(1)
        )
        if (response.status == 200) {
          this.confirmationResult = response.data
          this.state += 1
        }
      } catch (error) {
        // Error; SMS not sent
        console.log(error)
      } finally {
        this.isProcessing = false
      }
      // firebaseAuth
      //   .signInWithPhoneNumber(this.phoneNumberWithPrefixCountryCode, this.recaptchaVerifier)
      //   .then(confirmationResult => {
      //     // SMS sent. Prompt user to type the code from the message, then sign the
      //     // user in with confirmationResult.confirm(code).
      //     this.confirmationResult = confirmationResult
      //     this.state += 1
      //   })
      //   .catch(error => {
      //     // Error; SMS not sent
      //     console.log(error)
      //   })
      //   .finally(() => (this.isProcessing = false))
    } else {
      console.log('Invalid phone number')
    }
  }

  async doConfirmCode() {
    if (this.confirmCode.length < 6) return
    this.isProcessing = true
    try {
      const response = await ApiClient.confirmOTP({
        token: this.confirmationResult.token,
        pin: this.confirmCode,
      })
      if (!response.data.code) {
        const res = await ApiClient.updateUserProfile({
          phone: this.phoneNumber,
          is_verified_phone: true,
        })
        if (res.data) {
          this.onSuccess()
        }
      } else {
        alert(response.data.errors[0].message)
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.isProcessing = false
    }

    // this.confirmationResult
    //   .confirm(this.confirmCode)
    //   .then(async (result) => {
    //     // User signed in successfully.
    //     const res = await ApiClient.updateUserProfile({
    //       phone: this.phoneNumber,
    //       is_verified_phone: true,
    //     })
    //     if (res.data) {
    //       this.onSuccess()
    //     }
    //   })
    //   .catch((error) => {
    //     // User couldn't sign in (bad verification code?)
    //     console.log(error)
    //   })
    //   .finally(() => {
    //     this.isProcessing = false
    //   })
  }

  onSuccess() {
    this.$emit('onSuccess', this.phoneNumber)
  }

  t(key) {
    return this.$t(`pages.verify_phone.${key}`)
  }
}
</script>

<style lang="scss" scoped>
.white-mark {
  width: 24px;
  height: 24px;
}
</style>
